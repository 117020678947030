import React, { useEffect } from 'react';
import { useDrag } from 'react-dnd';

/** Other components */
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';

/** Helpers */
import CONFIG from 'helpers/const';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	box: props => ({
		opacity: props.isDragging ? 0.4 : 1,
	}),
	enabledGoal: { cursor: 'grab', margin: 5 },
	disabledGoal: { margin: 5 },
});

const YaGoal = ({ SettingsModel, goal, stageSave }) => {
	const goalId = goal.id;
	const goalName = goal.name;
	const goalUrl =  goal.conditions?.[0].url;

	const isDisabled = stage => {
		return CONFIG.YA_GOAL_ID in stage && stage[CONFIG.YA_GOAL_ID] === goalId;
	};

	let disabled = SettingsModel['dealsStages'].some(isDisabled);
	if (!disabled) disabled = SettingsModel['leadsStages'].some(isDisabled);

	const item = { goal, type: CONFIG.GOAL_DRAG_TYPE };

	const [{ isDragging }, drag] = useDrag({
		item,
		end(item, monitor) {
			const dropResult = monitor.getDropResult();

			if (
				goalId &&
				dropResult &&
				'stage' in dropResult &&
				'STATUS_ID' in dropResult.stage &&
				dropResult.stage.STATUS_ID
			)
				stageSave({
					STATUS_ID: dropResult.stage.STATUS_ID,
					IS_CONVERSION: dropResult.stage.IS_CONVERSION,
					yaGoalId: goalId,
					yaGoalName: goalName,
					yaGoalUrl: goalUrl
				});
		},
		collect: monitor => ({
			isDragging: monitor.isDragging(),
		}),
	});

	const classes = useStyles({ isDragging });

	return (
		<>
			{goalId ? (
				<span ref={disabled ? null : drag}>
					<Chip
						avatar={<Avatar src="/resources/YaMetricLogo.png" />}
						label={`${goal.name}`}
						clickable
						color="primary"
						disabled={disabled}
						className={classes.enabledGoal}
					/>
				</span>
			) : (
				<Tooltip placement={'top'} title="У этой цели нет меток">
					<Chip
						avatar={<Avatar>{goal.name.slice(0, 1)}</Avatar>}
						label={`${goal.name}`}
						disabled={disabled}
						className={classes.disabledGoal}
					/>
				</Tooltip>
			)}
		</>
	);
};

export default YaGoal;
